export default {

    methods: {
        getVendors(params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        getVendorUsers(params, vendorId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/users`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getVendorDetails(id) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${id}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },


        addNewVendor(obj) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.firstName !== null) {
                    bodyFormData.append("firstname", obj.firstName);
                }
                if (obj.lastName !== null) {
                    bodyFormData.append("lastname", obj.lastName);
                }
                if (obj.category.selectedCategories.length > 0) {
                    obj.category.selectedCategories.map((category, i) => {
                        bodyFormData.append(`vendor_category_ids[${i}]`, category._id);
                    })
                }
                if (obj.mobile !== null) {
                    bodyFormData.append("mobile", obj.mobile);
                }
                if (obj.email !== null) {
                    bodyFormData.append("email", obj.email);
                }
                if (obj.vendor !== null) {
                    bodyFormData.append("vendor", obj.vendor);
                }
                obj.selectedTags.map((tag,i)=>{
                    bodyFormData.append(`tags[${i}][is_new]`,`${tag.is_new?1:0}`)
                    if(tag.is_new===true){
                        bodyFormData.append(`tags[${i}][tag_title]`,`${tag.tag_title}`)
                    }
                    bodyFormData.append(`tags[${i}][tag_id]`,`${tag.tag_id?tag.tag_id:''}`)
                })
                if (obj.shouldInvite !== null) {
                    if (obj.shouldInvite === true) {
                        bodyFormData.append("should_invite", 1);
                    }
                    else {
                        bodyFormData.append("should_invite", 0);
                    }
                }


                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        addNewUserToVendor(obj, id) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.firstName !== null) {
                    bodyFormData.append("firstname", obj.firstName);
                }
                if (obj.lastName !== null) {
                    bodyFormData.append("lastname", obj.lastName);
                }
                if (obj.mobile !== null) {
                    bodyFormData.append("mobile", obj.mobile);
                }
                if (obj.email !== null) {
                    bodyFormData.append("email", obj.email);
                }

                if (obj.shouldInvite !== null) {
                    if (obj.shouldInvite === true) {
                        bodyFormData.append("should_invite", 1);
                    }
                    else {
                        bodyFormData.append("should_invite", 0);
                    }
                }


                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${id}/add-user`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        getVendorUsers(params, vendorId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/users`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getVendorUser(params, vendorId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getAssessmentsOfVendor(params, vendorId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/assessments`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getAssessmentDetailsOfVendor(vendorId, assessmentId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/assessments/${assessmentId}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getAssessmentQuestionnairesOfVendor(params, vendorId, assessmentId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/vendors/${vendorId}/assessments/${assessmentId}/questionnaires`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        
    },
}