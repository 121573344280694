<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card" style="box-shadow: none !important">
      <div class="card-header border-bottom d-flex">
        <div
          style="width: 70%"
          class="d-flex flex-column justify-content-center align-items-start"
        >
          <h4>
            <span class="align-middle font-weight-bolder"
              >Create New Assessment</span
            >
          </h4>
          <p class="card-subtitle text-muted pt-75">
            Fill the form and click submit to start a new assessment.
          </p>
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <b-button
            size="sm"
            @click="closeSidebar()"
            variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>
      <div class="card-body pt-3" v-if="questionnaire !== null">
        <validation-observer ref="tpr_assessment_form" #default="{ invalid }">
          <b-form class="mt-2" @submit.prevent="handleSubmitClick()">
            <b-form-group label="Name">
              <template slot="label">
                Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="title"
                name="Name"
                :rules="{
                  required: true,
                  min: 3,
                }"
              >
                <b-form-input
                  v-model="formData.title"
                  id="title"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Description">
              <validation-provider
                #default="{ errors }"
                vid="description"
                name="Description"
              >
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formData.description"
                  id="description"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Questionnaire">
              <template slot="label">
                Questionnaire <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Questionnaires"
                :rules="{
                  required: true,
                }"
              >
                <v-select
                  v-model="formData.selectedQuestionnaire"
                  multiple
                  placeholder="Search..."
                  :options="questionnaire"
                  @search="handleSearchFunction"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <!-- <div
                class="
                  w-100
                  mb-1
                  d-flex
                  flex-column
                  align-items-start
                  justify-content-center
                  p-1
                  rounded
                  border
                "
                style="min-height: 100px"
              >
                <template v-for="item in formData.selectedQuestionnaire">
                  <b-badge
                    :key="item._id"
                    variant="light-primary"
                    style="position: relative"
                    class="
                      d-flex
                      flex-direction-column
                      align-items-center
                      justify-content-between
                      my-25
                      cursor-pointer
                    "
                    v-b-tooltip.hover
                    :title="item.title"
                  >
                    <p class="truncate text-left mb-0">
                      {{ item.title }}
                    </p>
                    <div
                      @click="handleQuestionnaireRemoveClick(item._id)"
                      style="width: 27px"
                      class="
                        h-100
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      <feather-icon
                        icon="XIcon"
                        class="ml-75 text-danger"
                        size="27"
                      />
                    </div>
                  </b-badge>
                </template>
              </div>
              <add-or-remove-list
                title="Add Questionnaire"
                :buttonAddType="true"
                :buttonFunction="handleAddButtonClick"
                :searchFunction="handleSearchFunction"
                :items="questionnaire"
                :allowTooltip="true"
                :returnTypeIsObject="true"
              /> -->
            </b-form-group>

            <b-form-group label="Vendor Selection Type">
              <validation-provider
                #default="{ errors }"
                name="Vendor Selection Type"
                :rules="{ required: false }"
              >
                <b-form-select
                  v-model="formData.vendors.vendorInputType"
                  :options="formData.vendors.vendorInputTypes"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Specific Vendors -->
            <b-form-group
              v-if="formData.vendors.vendorInputType == 2"
              label="Vendors"
            >
              <template slot="label">
                Vendors <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Vendor Selection Type"
                :rules="{
                  required:
                    formData.vendors.vendorInputType == 2 ? true : false,
                }"
              >
                <vue-autosuggest
                  :suggestions="formData.vendors.suggestions"
                  :limit="5"
                  v-model="vendorFilters.name"
                  id="autosuggest__input"
                  :input-props="formData.vendors.vendorInputProps"
                  @input="getVendorsBySearchPhraseAndSetOptions"
                  @selected="onVendorSelected"
                  :get-suggestion-value="getVendorSuggestionValue"
                >
                  <template
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <span style="{ display: 'flex', color: 'navyblue'}"
                      >{{ suggestion.item.name }}
                    </span>
                  </template>
                </vue-autosuggest>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div
                class="d-flex flex-row flex-wrap align-items-center justify-content-start mt-75"
              >
                <b-badge
                  class="mr-25 mt-25"
                  v-for="vendor in formData.vendors.selectedVendors"
                  variant="light-primary"
                  :key="vendor._id"
                  >{{ vendor.name
                  }}<feather-icon
                    @click="handleVendorRemoveAction(vendor._id)"
                    class="text-danger ml-25 cursor-pointer"
                    icon="XIcon"
                /></b-badge>
              </div>
            </b-form-group>

            <!-- Vendor Category -->

            <b-form-group
              label="Vendor Category"
              v-if="formData.vendors.vendorInputType == 1"
            >
              <template slot="label">
                Vendor Category <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Vendor Category"
                :rules="{
                  required:
                    formData.vendors.vendorInputType == 1 ? true : false,
                }"
              >
                <b-form-select
                  v-model="selectedCategory"
                  :options="categoryOptions"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div
                class="d-flex flex-row flex-wrap align-items-center justify-content-start mt-75"
              >
                <b-badge
                  class="mr-25 mt-25"
                  v-for="category in formData.selectedCategories"
                  variant="light-primary"
                  :key="category.value"
                  >{{ category.text
                  }}<feather-icon
                    @click="handleCategoryRemoveAction(category.value)"
                    class="text-danger ml-25 cursor-pointer"
                    icon="XIcon"
                /></b-badge>
              </div>
            </b-form-group>

            <!-- Exclude Vendors -->
            <b-form-group
              v-if="
                formData.vendors.vendorInputType == 0 ||
                formData.vendors.vendorInputType == 1
              "
              label="Excluded Vendors"
            >
              <template slot="label">
                Excluded Vendors <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Excluded Vendors"
                :rules="{
                  required: false,
                }"
              >
                <vue-autosuggest
                  :suggestions="formData.vendors.suggestions"
                  :limit="5"
                  v-model="vendorFilters.name"
                  id="autosuggest__input"
                  :input-props="formData.vendors.exclution.vendorInputProps"
                  @input="getVendorsBySearchPhraseAndSetOptions"
                  @selected="onExcultionVendorSelected"
                  :get-suggestion-value="getVendorSuggestionValue"
                >
                  <template
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <span style="{ display: 'flex', color: 'navyblue'}"
                      >{{ suggestion.item.name }}
                    </span>
                  </template>
                </vue-autosuggest>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div
                class="d-flex flex-row flex-wrap align-items-center justify-content-start mt-75"
              >
                <b-badge
                  class="mr-25 mt-25"
                  v-for="vendor in formData.vendors.exclution.selectedVendors"
                  variant="light-primary"
                  :key="vendor._id"
                  >{{ vendor.name
                  }}<feather-icon
                    @click="handleExclutionVendorRemoveAction(vendor._id)"
                    class="text-danger ml-25 cursor-pointer"
                    icon="XIcon"
                /></b-badge>
              </div>
            </b-form-group>

            <b-form-group label="Tags" class="w-100">
              <vue-autosuggest
                :suggestions="tagOptions"
                :limit="5"
                v-model="tagName"
                id="autosuggest__input"
                :input-props="tagInputProps"
                @input="getAssetTagsBySearchPhraseAndSetData"
                @selected="onTagSelected"
                :get-suggestion-value="getTagSuggestionValue"
              >
                <template
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  <span style="{ display: 'flex', color: 'navyblue'}">{{
                    suggestion.item.title
                  }}</span>
                </template>
              </vue-autosuggest>
              <div
                class="d-flex flex-wrap align-items-center justify-content-start mt-75"
              >
                <template v-for="(value, i) in formData.selectedTags">
                  <b-badge :key="i" variant="light-primary" class="mr-1">
                    <span>{{ value.tag_title }}</span>
                    <feather-icon
                      @click="removeTag(value.tag_title)"
                      icon="XCircleIcon"
                      class="cursor-pointer ml-50 text-danger"
                    />
                  </b-badge>
                </template>
              </div>
            </b-form-group>

            <div class="w-100 d-flex justify-content-center align-items-center">
              <b-button
                type="submit"
                variant="primary"
                class="mt-1 w-25"
                :disabled="invalid"
              >
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Submit</span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BFormTextarea,
  BBadge,
  VBHover,
  VBTooltip,
  BModal,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min } from "@validations";

import AddOrRemoveList from "@/components/AddOrRemoveList.vue";
import { VueAutosuggest } from "vue-autosuggest";
import ResponseMixins from "../../../../mixins/ResponseMixins";
import VendorMixins from "../../../../mixins/VendorMixins";
import UtilsMixins from "../../../../mixins/UtilsMixins";
import QuestionnaireMixins from "../../../../mixins/QuestionnaireMixins";
import ThirdPartyRisksRAMixins from "../../../../mixins/ThirdPartyRisksRAMixins";
import TagMixins from "../../../../mixins/TagMixins";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    required,
    min,
    AddOrRemoveList,
    VueAutosuggest,
    VBHover,
    VBTooltip,
    BModal,
    BFormTextarea,
    vSelect,
  },
  directives: {
    "b-hover": VBHover,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showOverlay: false,
      questionnaire: null,
      categoryOptions: [{ value: null, text: "--Select Category--" }],
      selectedCategory: null,
      newCategory: {
        title: null,
        description: null,
      },
      filters: {
        search: null,
        dont_paginate: true,
      },
      vendorFilters: {
        name: null,
      },
      formData: {
        title: null,
        description: null,
        selectedCategories: [],
        selectedTags: [],
        vendors: {
          vendorInputTypes: [
            { value: 0, text: "Select All" },
            { value: 1, text: "Select by Category" },
            { value: 2, text: "Select Specific Vendors" },
          ],
          vendorInputType: 0,
          exclution: {
            selectedVendors: [],
            vendorInputProps: {
              class: "form-control",
              placeholder: "Search vendors..",
            },
          },
          suggestions: [],
          selectedVendors: [],
          vendorInputProps: {
            class: "form-control",
            placeholder: "Search vendors..",
          },
        },
        selectedQuestionnaire: [],
      },
      tagOptions: [],
      tagName: "",
      tagInputProps: {
        class: "form-control",
        placeholder: "Search & Select Tags..",
      },
    };
  },
  mixins: [
    UtilsMixins,
    VendorMixins,
    QuestionnaireMixins,
    ResponseMixins,
    ThirdPartyRisksRAMixins,
    TagMixins,
  ],
  props: {
    closeSidebar: {
      type: Function,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.load();
  },

  watch: {
    selectedCategory(newValue) {
      this.handleNewCategorySelected(newValue);
    },
    filters: {
      handler: function (newValue) {
        this.getQuestionnaireAndSetData(newValue);
      },
      deep: true,
    },
    vendorFilters: {
      handler: function (newValue) {
        this.getVendorsBySearchPhraseAndSetOptions(newValue);
      },
      deep: true,
    },
  },
  methods: {
    load() {
      this.getQuestionnaireAndSetData(this.filters);
      this.getCategoriesAndSetData({ dont_paginate: true });
    },

    getAssetTagsBySearchPhraseAndSetData(searchPhrase) {
      if (searchPhrase !== "") {
        this.debounceFunction(() => {
          this.getAssetTagsBySearchPhrase(searchPhrase)
            .then((res) => {
              this.tagOptions = [];
              const newTags = res.data.data.data;
              if (newTags.length == 0) {
                newTags.push({ title: searchPhrase, is_new: true });
              }
              this.tagOptions.push({ name: "tags", data: newTags });
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      } else {
        this.tagOptions = [];
      }
    },
    onTagSelected(val) {
      if (val) {
        const selectedVal = val.item;
        const tagIndex = this.formData.selectedTags.findIndex(
          (tag) => tag.tag_title === selectedVal.title
        );
        if (tagIndex === -1) {
          this.tagName = "";
          this.formData.selectedTags.push({
            tag_id: selectedVal._id,
            tag_title: selectedVal.title,
            is_new: selectedVal._id ? false : true,
          });
        }
      }
    },

    removeTag(tagName) {
      let newSelectedTags = this.formData.selectedTags.filter(
        (tag) => tag.tag_title !== tagName
      );
      this.formData.selectedTags = newSelectedTags;
    },

    getTagSuggestionValue(suggestion) {
      return suggestion.item.title;
    },

    handleNewCategorySelected(selectedCategoryId) {
      let categoryOptionsIndex = this.categoryOptions.findIndex(
        (category) => category.value === selectedCategoryId
      );
      if (categoryOptionsIndex !== -1) {
        if (this.formData.selectedCategories.length == 0) {
          this.formData.selectedCategories.push(
            this.categoryOptions[categoryOptionsIndex]
          );
        }
        let selectedCategoryIndex = this.formData.selectedCategories.findIndex(
          (category) =>
            category.value === this.categoryOptions[categoryOptionsIndex].value
        );
        if (selectedCategoryIndex == -1) {
          this.formData.selectedCategories.push(
            this.categoryOptions[categoryOptionsIndex]
          );
        }
      }
    },

    handleCategoryRemoveAction(categoryId) {
      let categoryIndex = this.formData.selectedCategories.findIndex(
        (category) => category.value == categoryId
      );
      if (categoryIndex !== -1) {
        let newCategories = this.formData.selectedCategories.filter(
          (category) => category.value !== categoryId
        );
        this.formData.selectedCategories = newCategories;
      }
    },

    handleSubmitClick() {
      this.showOverlay = true;
      this.createAssessment(this.formData)
        .then((res) => {
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
          this.reloadParent();
          this.closeSidebar();
        });
    },

    getCategoriesAndSetData(params) {
      this.showOverlay = true;
      this.categoryOptions = [{ value: null, text: "--Select Category--" }];
      this.getAssessmentCategories(params)
        .then((res) => {
          res.data.data.map((category) => {
            this.categoryOptions.push({
              value: category._id,
              text: category.title,
            });
          });
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    getQuestionnaireAndSetData(params) {
      this.showOverlay = true;
      this.getImportedQuestionnaires(params)
        .then((res) => {
          console.log("Questionnaires", res.data.data);
          // this.questionnaire = res.data.data;
          this.questionnaire = res.data.data.map((qr) => {
            return { value: qr._id, text: qr.title, label: qr.title, ...qr };
          });
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    getVendorsBySearchPhraseAndSetOptions(params) {
      this.debounceFunction(() => {
        this.getVendors(params)
          .then((res) => {
            this.formData.vendors.suggestions.push({
              name: "vendors",
              data: res.data.data.data,
            });
          })
          .catch((err) => {
            this.handleError(err);
          });
      });
    },
    onVendorSelected(value) {
      this.formData.vendors.selectedVendors.push(value.item);
    },
    onExcultionVendorSelected(value) {
      this.formData.vendors.exclution.selectedVendors.push(value.item);
    },
    getVendorSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    handleVendorRemoveAction(id) {
      let newSelectedVendors = this.formData.vendors.selectedVendors.filter(
        (vendor) => vendor._id !== id
      );
      this.formData.vendors.selectedVendors = newSelectedVendors;
    },
    handleExclutionVendorRemoveAction(id) {
      let newSelectedVendors =
        this.formData.vendors.exclution.selectedVendors.filter(
          (vendor) => vendor._id !== id
        );
      this.formData.vendors.exclution.selectedVendors = newSelectedVendors;
    },

    handleSearchFunction(searchPhrase) {
      this.filters.search = searchPhrase;
    },
    handleAddButtonClick(questionnaire) {
      if (this.formData.selectedQuestionnaire.length > 0) {
        const questionnaireIndex =
          this.formData.selectedQuestionnaire.findIndex(
            (q) => q._id === questionnaire._id
          );
        if (questionnaireIndex == -1) {
          this.formData.selectedQuestionnaire.push(questionnaire);
        }
      } else {
        this.formData.selectedQuestionnaire.push(questionnaire);
      }
    },
    handleQuestionnaireRemoveClick(id) {
      let newQuestionnaire = this.formData.selectedQuestionnaire.filter(
        (q) => q._id !== id
      );
      this.formData.selectedQuestionnaire = newQuestionnaire;
    },
  },
};
</script>

<style lang="css" scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>

