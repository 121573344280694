
export default {

    methods: {

        addQuestionToQuestionnaire(obj, questionnaireId) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.title !== null) {
                    bodyFormData.append("title", obj.title);
                }
                if (obj.description !== null) {
                    bodyFormData.append("description", obj.description);
                }
                if (obj.evidenceRequired !== null) {
                    bodyFormData.append("is_evidence_rqd", obj.evidenceRequired == true ? 1 : 0);
                }
                if (obj.responseMandatory !== null) {
                    bodyFormData.append("is_response_mandatory", obj.responseMandatory == true ? 1 : 0);
                }
                if (obj.answer !== null) {
                    bodyFormData.append("answer", obj.answer);
                }
                if (obj.parentQuestionId !== null) {
                    bodyFormData.append("parent_question_id", obj.parentQuestionId);
                }
                if (obj.parentQuestionResponse !== null) {
                    bodyFormData.append("parent_question_response", obj.parentQuestionResponse);
                }
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}/questions`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        updateQuestionOnQuestionnaire(obj, questionnaireId, questionId) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.title !== null) {
                    bodyFormData.append("title", obj.title);
                }
                if (obj.description !== null) {
                    bodyFormData.append("description", obj.description);
                }
                if (obj.evidenceRequired !== null) {
                    bodyFormData.append("is_evidence_rqd", obj.evidenceRequired == true ? 1 : 0);
                }
                if (obj.responseMandatory !== null) {
                    bodyFormData.append("is_response_mandatory", obj.responseMandatory == true ? 1 : 0);
                }
                if (obj.answer !== null) {
                    bodyFormData.append("answer", obj.answer);
                }
                if (obj.parentQuestionId !== null) {
                    bodyFormData.append("parent_question_id", obj.parentQuestionId);
                }
                if (obj.parentQuestionResponse !== null) {
                    bodyFormData.append("parent_question_response", obj.parentQuestionResponse);
                }
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}/questions/${questionId}`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        getQuestionsFromQuestionnaire(params, questionnaireId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}/questions`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getQuestionDetails(questionnaireId, questionId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}/questions/${questionId}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        removeQuestionFromQuestionnaire(questionnaireId, questionId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "DELETE",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}/questions/${questionId}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getImportedQuestionnaires(params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getImportedQuestionnaireDetails(questionnaireId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        createCustomQuestionnaire(obj) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.title !== null) {
                    bodyFormData.append("title", obj.title);
                }
                if (obj.description !== null) {
                    bodyFormData.append("description", obj.description);
                }
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        removeQuestionnaire(questionnaireId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "DELETE",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/${questionnaireId}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getImportedQuestionnaireQuestions(params, questionnaireId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/:${questionnaireId}/questions`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getNonImportedQuestionnaire(params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/non-imported`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getNonImportedQuestionnaireDetails(params, questionnaireId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/non-imported/${questionnaireId}`,
                    params: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        importNonImportedQuestionnaire(questionnaireId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tpr/questionnaires/non-imported/${questionnaireId}/import`,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

    },
}