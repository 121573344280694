<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <b-sidebar
      lazy
      id="sidebar-add-assessment"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      width="600px"
    >
      <NewTPRRAAssessment
        :reloadParent="load"
        :closeSidebar="closeAddAssessmentSidebar"
      />
    </b-sidebar>
    <b-sidebar
      lazy
      id="sidebar-edit-assessment"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      width="600px"
    >
      <EditTPRRAAssessment
        v-if="selectedAssessmentId !== null"
        :reloadParent="load"
        :closeSidebar="closeEditAssessmentSidebar"
        :assessmentId="selectedAssessmentId"
      />
    </b-sidebar>
    <div class="card" v-if="assessments !== null">
      <div class="card-header">
        <b-button
          v-if="!isUserVendor"
          @click="handleNewAssessmentClick"
          variant="primary"
          ><feather-icon class="mr-50" icon="PlusIcon" />New
          Assessment</b-button
        >
      </div>
      <div class="card-body">
        <div>
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">Name</th>
                <th role="columnheader" scope="col">Description</th>
                <th role="columnheader" scope="col" class="text-center">
                  Status
                </th>
                <th role="columnheader" scope="col" class="text-center">
                  Questionnaires
                </th>
                <th role="columnheader" scope="col" class="text-center">
                  Progress
                </th>
                <th role="columnheader" scope="col" class="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <tr
                v-for="assessment in assessments"
                :key="assessment._id"
                role="row"
                class="cursor-pointer"
                @click="handleAssessmentClick(assessment._id)"
              >
                <td role="cell" style="width: 160px">
                  <div
                    class="d-flex justify-content-start"
                    style="width: 160px"
                    v-b-tooltip.hover.top.v-secondary
                    :title="assessment.title"
                  >
                    <p class="text-left font-weight-bolder text-truncate mb-0">
                      {{ assessment.title }}
                    </p>
                  </div>
                </td>

                <td role="cell" style="width: 160px;">
                  <div
                    class="d-flex justify-content-start"
                    style="width: 160px"
                  >
                    <p
                      class="text-left font-weight-bold mb-0 text-truncate"
                      v-b-tooltip.hover.top.v-secondary
                      :title="assessment.description"
                    >
                      {{ assessment.description }}
                    </p>
                  </div>
                </td>

                <td role="cell">
                  <div class="d-flex justify-content-center">
                    <b-badge :variant="getStatusColor(assessment.status)">{{
                      getStatusNumber(assessment.status)
                    }}</b-badge>
                  </div>
                </td>

                <td role="cell">
                  <div class="d-flex justify-content-center">
                    <b-avatar
                      :text="assessment.total_questionnaires.toString()"
                      variant="light-primary"
                    />
                  </div>
                </td>

                <td role="cell">
                  <div
                    class="d-flex justify-content-center align-items-center"
                  >
                    <!-- <donut-bar
                      :percentage="Number(assessment.completion.toFixed(2))"
                      :displayValue="assessment.completion + '%'"
                      size="xsm"
                      :uniqueId="assessment._id + 2"
                      :colorsReversed="true"
                    /> -->
                    <progress-badge :min="0" :max="100" title="Progress" :value="assessment.completion"  />
                  </div>
                </td>
                <td role="cell">
                  <div class="d-flex justify-content-center">
                    <b-button
                      @click.stop="handleEditClick(assessment._id)"
                      b-v-tooltip.hover.top.v-secondary
                      title="Edit Assessment"
                      variant="primary"
                      size="sm"
                      ><feather-icon icon="EditIcon" class="mr-50"
                    /><span class="align-middle">Edit</span></b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-pagination
              class="mt-2"
              v-model="assessmentFilters.page"
              :per-page="assessmentPagination.perPage"
              :total-rows="
                assessmentPagination.totalPages * assessmentPagination.perPage
              "
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BSidebar,
  BButton,
  BAvatar,
  BPagination,
  BTabs,
  BTab,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import DonutBar from "@/components/DonutBar.vue";
import ProgressBadge from "@/components/ProgressBadge.vue";

import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import ThirdPartyRisksRAMixins from "../../../mixins/ThirdPartyRisksRAMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import TaskMixins from "../../../mixins/TaskMixins";
import UtilsMixins from "../../../mixins/UtilsMixins";
import NewTPRRAAssessment from "./components/NewTPRRAAssessment.vue";
import EditTPRRAAssessment from "./components/EditTPRRAAssessment.vue";

export default {
  components: {
    BOverlay,
    BSidebar,
    BButton,
    BAvatar,
    DonutBar,
    BPagination,
    BTabs,
    BTab,
    BBadge,
    NewTPRRAAssessment,
    EditTPRRAAssessment,
    ProgressBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showOverlay: false,
      vendor: null,
      importedQuestionnaire: null,
      selectedAssessmentId: null,
      assessments: null,
      isUserVendor: false,
      vendorUsers: null,
      vendorUsersFilters: {
        page: 1,
        perPage: 10,
        totalPages: 1,
      },
      assessmentFilters: {
        page: 1,
      },
      assessmentPagination: {
        perPage: 10,
        totalPages: 1,
      },
    };
  },

  mixins: [
    ResponseMixins,
    TaskMixins,
    UtilsMixins,
    QuestionnaireMixins,
    ThirdPartyRisksRAMixins,
  ],

  watch: {
    vendorUsersFilters: {
      handler: function (newValue) {
        this.getVendorUsersAndSetData(newValue);
      },
      deep: true,
    },
    assessmentFilters: {
      handler: function (newValue) {
        this.getAssessmentsAndSetData(newValue);
      },
      deep: true,
    },
  },

  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }

      this.getAssessmentsAndSetData(
        this.assessmentFilters,
        this.$route.params.id
      );
    },

    handleEditClick(assessmentId) {
      this.selectedAssessmentId = assessmentId;
      this.openEditAssessmentSidebar();
    },

    getStatusNumber(num) {
      switch (num) {
        case 1:
          return "In Progress";
        case 2:
          return "Completed";
        default:
          return "Unknown";
      }
    },
    getStatusColor(num) {
      switch (num) {
        case 1:
          return "info";
        case 2:
          return "success";
        default:
          return "info";
      }
    },

    handleAssessmentClick(id) {
      this.$router.push(`/thirdpartyrisksRA/assessments/${id}/details`);
    },

    getAssessmentsAndSetData(filters) {
      this.showOverlay = true;
      this.getAssessments(filters)
        .then((res) => {
          this.assessments = res.data.data.data;
          this.assessmentFilters.page = res.data.data.current_page;
          this.assessmentPagination.perPage = res.data.data.per_page;
          this.assessmentPagination.totalPages = res.data.data.last_page;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewAssessmentClick() {
      this.openAddAssessmentSidebar();
    },

    getImportedQuestionnaireAndSetData(vendorId) {
      this.showOverlay = true;
      this.getQuestionnaire({}, vendorId)
        .then((res) => {
          this.importedQuestionnaire = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeAddAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-add-assessment");
    },
    openAddAssessmentSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-add-assessment");
      });
    },
    closeEditAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit-assessment");
    },
    openEditAssessmentSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-edit-assessment");
      });
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>