var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('div',{staticClass:"card",staticStyle:{"box-shadow":"none !important"}},[_c('div',{staticClass:"card-header border-bottom d-flex"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-start",staticStyle:{"width":"70%"}},[_c('h4',[_c('span',{staticClass:"align-middle font-weight-bolder"},[_vm._v("Create New Assessment")])]),_c('p',{staticClass:"card-subtitle text-muted pt-75"},[_vm._v(" Fill the form and click submit to start a new assessment. ")])]),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.closeSidebar()}}},[_c('feather-icon',{attrs:{"size":"24","icon":"XIcon"}})],1)],1)]),(_vm.questionnaire !== null)?_c('div',{staticClass:"card-body pt-3"},[_c('validation-observer',{ref:"tpr_assessment_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitClick()}}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('template',{slot:"label"},[_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"title","name":"Name","rules":{
                required: true,
                min: 3,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"vid":"description","name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3","max-rows":"6","id":"description","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Questionnaire"}},[_c('template',{slot:"label"},[_vm._v(" Questionnaire "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Questionnaires","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","placeholder":"Search...","options":_vm.questionnaire},on:{"search":_vm.handleSearchFunction},model:{value:(_vm.formData.selectedQuestionnaire),callback:function ($$v) {_vm.$set(_vm.formData, "selectedQuestionnaire", $$v)},expression:"formData.selectedQuestionnaire"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Vendor Selection Type"}},[_c('validation-provider',{attrs:{"name":"Vendor Selection Type","rules":{ required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.formData.vendors.vendorInputTypes},model:{value:(_vm.formData.vendors.vendorInputType),callback:function ($$v) {_vm.$set(_vm.formData.vendors, "vendorInputType", $$v)},expression:"formData.vendors.vendorInputType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.formData.vendors.vendorInputType == 2)?_c('b-form-group',{attrs:{"label":"Vendors"}},[_c('template',{slot:"label"},[_vm._v(" Vendors "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Vendor Selection Type","rules":{
                required:
                  _vm.formData.vendors.vendorInputType == 2 ? true : false,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.formData.vendors.suggestions,"limit":5,"id":"autosuggest__input","input-props":_vm.formData.vendors.vendorInputProps,"get-suggestion-value":_vm.getVendorSuggestionValue},on:{"input":_vm.getVendorsBySearchPhraseAndSetOptions,"selected":_vm.onVendorSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.name)+" ")])]}}],null,true),model:{value:(_vm.vendorFilters.name),callback:function ($$v) {_vm.$set(_vm.vendorFilters, "name", $$v)},expression:"vendorFilters.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row flex-wrap align-items-center justify-content-start mt-75"},_vm._l((_vm.formData.vendors.selectedVendors),function(vendor){return _c('b-badge',{key:vendor._id,staticClass:"mr-25 mt-25",attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(vendor.name)),_c('feather-icon',{staticClass:"text-danger ml-25 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.handleVendorRemoveAction(vendor._id)}}})],1)}),1)],2):_vm._e(),(_vm.formData.vendors.vendorInputType == 1)?_c('b-form-group',{attrs:{"label":"Vendor Category"}},[_c('template',{slot:"label"},[_vm._v(" Vendor Category "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Vendor Category","rules":{
                required:
                  _vm.formData.vendors.vendorInputType == 1 ? true : false,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.categoryOptions},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row flex-wrap align-items-center justify-content-start mt-75"},_vm._l((_vm.formData.selectedCategories),function(category){return _c('b-badge',{key:category.value,staticClass:"mr-25 mt-25",attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(category.text)),_c('feather-icon',{staticClass:"text-danger ml-25 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.handleCategoryRemoveAction(category.value)}}})],1)}),1)],2):_vm._e(),(
              _vm.formData.vendors.vendorInputType == 0 ||
              _vm.formData.vendors.vendorInputType == 1
            )?_c('b-form-group',{attrs:{"label":"Excluded Vendors"}},[_c('template',{slot:"label"},[_vm._v(" Excluded Vendors "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Excluded Vendors","rules":{
                required: false,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.formData.vendors.suggestions,"limit":5,"id":"autosuggest__input","input-props":_vm.formData.vendors.exclution.vendorInputProps,"get-suggestion-value":_vm.getVendorSuggestionValue},on:{"input":_vm.getVendorsBySearchPhraseAndSetOptions,"selected":_vm.onExcultionVendorSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.name)+" ")])]}}],null,true),model:{value:(_vm.vendorFilters.name),callback:function ($$v) {_vm.$set(_vm.vendorFilters, "name", $$v)},expression:"vendorFilters.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row flex-wrap align-items-center justify-content-start mt-75"},_vm._l((_vm.formData.vendors.exclution.selectedVendors),function(vendor){return _c('b-badge',{key:vendor._id,staticClass:"mr-25 mt-25",attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(vendor.name)),_c('feather-icon',{staticClass:"text-danger ml-25 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.handleExclutionVendorRemoveAction(vendor._id)}}})],1)}),1)],2):_vm._e(),_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Tags"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.tagOptions,"limit":5,"id":"autosuggest__input","input-props":_vm.tagInputProps,"get-suggestion-value":_vm.getTagSuggestionValue},on:{"input":_vm.getAssetTagsBySearchPhraseAndSetData,"selected":_vm.onTagSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.title))])]}}],null,true),model:{value:(_vm.tagName),callback:function ($$v) {_vm.tagName=$$v},expression:"tagName"}}),_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-start mt-75"},[_vm._l((_vm.formData.selectedTags),function(value,i){return [_c('b-badge',{key:i,staticClass:"mr-1",attrs:{"variant":"light-primary"}},[_c('span',[_vm._v(_vm._s(value.tag_title))]),_c('feather-icon',{staticClass:"cursor-pointer ml-50 text-danger",attrs:{"icon":"XCircleIcon"},on:{"click":function($event){return _vm.removeTag(value.tag_title)}}})],1)]})],2)],1),_c('div',{staticClass:"w-100 d-flex justify-content-center align-items-center"},[_c('b-button',{staticClass:"mt-1 w-25",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Submit")])],1)],1)],1)]}}],null,false,1237531213)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }